"use client";
import Link from "next/link";
import Image from "next/image";
import styled from "@emotion/styled";
import { Fragment, useState } from "react";
import { AppBar, Grid, Box, Typography, Button } from "@mui/material";

import config from "config/env";
import SwiperSlide from "components/swipper";
import { setURL } from "utils/outclicks";
import { MenuItems } from "utils/drawerItem";
import { SearchParamsHook } from "lib/utm";

import MobileDrawer from "./drawer";
import DesktopMenu from "./DesktopMenu";

const AnnouncementBar = styled(Grid)`
  background: #41528d;
  padding: 10px 0px;
  font-family: Nunito Sans;
  font-weight: 400;
  font-style: normal;
  color: #fff;
  font-size: 13.6px;
  line-height: 2;
  letter-spacing: 0.025em;
  text-align: center;
  @media only screen and (max-width: 600px) {
    font-size: 12px;
  }
  @media only screen and (min-width: 1600px) {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  @media only screen and (min-width: 1900px) {
    padding-left: 21rem !important;
    padding-right: 21rem !important;
  }
`;

const MenuBar = styled(Grid)`
  background: #fff;
  padding: 0px 30px;
  color: #000;
  @media only screen and (min-width: 1600px) {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  @media only screen and (min-width: 1900px) {
    padding-left: 21rem !important;
    padding-right: 21rem !important;
  }
  @media only screen and (max-width: 750px) {
    display: none;
  }
`;

const Logo = styled(Grid)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  align-content: center;
  padding: 0px 0px 20px 0px;
  background-color: #ffffff;
  a {
    display: flex;
    align-items: center;
  }
  @media only screen and (max-width: 750px) {
    padding: 17px 10px !important;
    flex-direction: row;
  }
`;

const HeaderItems = styled(Typography)`
  color: #000;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 13.6px;
  display: inline-block;
  padding: 5px 10px;
  line-height: 2;
  letter-spacing: 0.025em;
`;

const SubHeaderItems = styled(Typography)`
  color: #000;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 13px;
  display: inline-block;
  padding: 7.5px 15px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  background-image: linear-gradient(#000 0 0);
  background-position: left -100% bottom 0; /*OR bottom left*/
  background-size: 0% 2px;
  background-repeat: no-repeat;
  transition:
    background-size 0.5s,
    background-position 0s 0.5s;
  &:hover {
    background-position: left -100% bottom 0; /*OR bottom right*/
    background-size: 100% 2px;
  }
`;

const MenuIconBox = styled(Box)`
  position: absolute;
  top: 54px;
  left: -5px;
  @media only screen and (min-width: 751px) {
    display: none;
  }
`;

const DesktopItemBox = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
  @media only screen and (max-width: 750px) {
    display: none;
  }
`;

const LogoDiv = styled.div`
  position: relative;
  width: 240px;
  height: 0px;
  padding-bottom: 42px;
  @media only screen and (max-width: 750px) {
    width: 160px;
    padding-bottom: 28px;
  }
`;

const announcementBar = ["DELIVERY 2-7 DAYS. EASY RETURNS & FULL REFUNDS"];

export default function Header() {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuSubItems, setMenuSubItems] = useState([]);

  const menuOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const queryParams = SearchParamsHook();
  return (
    <>
      <AppBar
        position="sticky"
        color="transparent"
        elevation={0}
        sx={{ zIndex: "9", boxShadow: "0 0 1px rgba(0, 0, 0, 0.2)" }}
        className="C001"
      >
        <Grid container className="C001">
          <AnnouncementBar item xs={12} className="C001-announcement">
            <SwiperSlide slides={announcementBar} sliderId="announcementBar" />
          </AnnouncementBar>

          <MenuBar item xs={12} className="C001-desktop-menu">
            <Box
              display="flex"
              flexDirection="row"
              className="C001-desktop-menu"
            >
              <Link
                href="https://morataara.com/apps/return_prime"
                className="C001-desktop-menu-returns"
              >
                <HeaderItems className="C001-desktop-menu-returns">
                  Returns
                </HeaderItems>
              </Link>
              <Link
                href={setURL(`${config.brandURL}/pages/gst-bill`, queryParams)}
                className="C001-desktop-menu-gst"
              >
                <HeaderItems className="C001-desktop-menu-gst">
                  GST Bill
                </HeaderItems>
              </Link>
              <Link
                href={setURL(
                  `${config.brandURL}/pages/bulk-order-enquiry`,
                  queryParams,
                )}
                className="C001-desktop-menu-bulk"
              >
                <HeaderItems className="C001-desktop-menu-bulk">
                  Bulk Orders
                </HeaderItems>
              </Link>
              <Link
                href={setURL(
                  `${config.brandURL}/pages/contact-us`,
                  queryParams,
                )}
                className="C001-desktop-menu-contact"
              >
                <HeaderItems className="C001-desktop-menu-contact">
                  Contact Us
                </HeaderItems>
              </Link>
              <Link
                href={setURL(`${config.brandURL}/pages/about-us`, queryParams)}
                className="C001-desktop-menu-about"
              >
                <HeaderItems className="C001-desktop-menu-about">
                  About Us
                </HeaderItems>
              </Link>
            </Box>
          </MenuBar>

          <Logo item xs={12} className="C002">
            <MenuIconBox className="C002-menu">
              <Button
                sx={{ color: "#2e2e2e" }}
                onClick={() => setOpen(true)}
                className="C002-menu"
              >
                <Image
                  src="/static/hamburger.svg"
                  alt="hamburger"
                  width={30}
                  height={30}
                  loading="eager"
                  fetchPriority="high"
                  priority
                  unoptimized={true}
                  className="C002-menu"
                />
              </Button>
            </MenuIconBox>
            <Link
              href={setURL(`${config.brandURL}`, queryParams)}
              className="C002-logo"
            >
              <LogoDiv>
                <Image
                  src="/static/logo.png"
                  fill
                  alt="logo"
                  priority
                  loading="eager"
                  fetchPriority="high"
                  unoptimized={true}
                  className="C002-logo"
                />
              </LogoDiv>
            </Link>
            <DesktopItemBox className="C002-desktop-menu">
              {MenuItems.map((item, idx) => {
                return !item.children.length ? (
                  <Link
                    href={item.link}
                    style={{ color: "#222" }}
                    key={item.value}
                    className={`C002-desktop-menu-${idx}`}
                  >
                    <Button
                      sx={{ padding: "0px" }}
                      className={`C002-desktop-menu-${idx}`}
                    >
                      <SubHeaderItems className={`C002-desktop-menu-${idx}`}>
                        {item.value}
                      </SubHeaderItems>
                    </Button>
                  </Link>
                ) : (
                  <Fragment key={item.value}>
                    <Button
                      id={`fade-button-${idx}`}
                      onClick={(e) => {
                        handleClick(e);
                        setMenuSubItems(item.children);
                      }}
                      onMouseOver={(e) => {
                        handleClick(e);
                        setMenuSubItems(item.children);
                      }}
                      sx={{ padding: "0px" }}
                      className={`C002-desktop-menu-${idx}`}
                    >
                      <Link
                        href={item.link}
                        className={`C002-desktop-menu-${idx}`}
                      >
                        <SubHeaderItems className={`C002-desktop-menu-${idx}`}>
                          {item.value}
                        </SubHeaderItems>
                      </Link>
                    </Button>
                    <DesktopMenu
                      open={menuOpen}
                      anchorEl={anchorEl}
                      id={`fade-button-${idx}`}
                      handleClose={handleClose}
                      item={menuSubItems}
                      itemValue={item.value}
                      parentIdx={idx}
                    />
                  </Fragment>
                );
              })}
            </DesktopItemBox>
          </Logo>
        </Grid>
      </AppBar>
      <MobileDrawer open={open} setOpen={setOpen} />
    </>
  );
}
