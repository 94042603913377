const { default: config } = require("config/env");

export const MenuItems = [
  {
    value: "New",
    link: `/collections/66964b6ba19786a88722b230`,
    children: [],
  },
  {
    value: "Decor",
    link: `/collections/66964b6aa19786a88722b1de`,
    children: [
      {
        value: "Decorative Objects",
        link: `/collections/66964b6aa19786a88722b204`,
      },
      {
        value: "Animal Figurines",
        link: `/collections/66964b6aa19786a88722b1e2`,
      },
      {
        value: "Incense Holders",
        link: `/collections/66964b6aa19786a88722b225`,
      },
      {
        value: "Brass Decor Objects",
        link: `/collections/66964b6aa19786a88722b1f7`,
      },
      {
        value: "Evil Eye Wall Hangings",
        link: `/collections/66964b6aa19786a88722b20b`,
      },
      {
        value: "Doorbells & Windchimes",
        link: `/collections/66964b6aa19786a88722b209`,
      },
      {
        value: "Furniture",
        link: `/collections/66964b6aa19786a88722b20e`,
      },
      {
        value: "Photo Frames",
        link: `/collections/66964b6ba19786a88722b233`,
      },
      {
        value: "Wall Art",
        link: `/collections/66964b6ba19786a88722b24d`,
      },
      {
        value: "Cushions",
        link: `/collections/66964b6aa19786a88722b201`,
      },
      {
        value: "Wooden Decor Accessories",
        link: `/collections/66964b6ba19786a88722b250`,
      },
    ],
  },
  {
    value: "Dining & Serving",
    link: `/collections/66964b6aa19786a88722b1db`,
    children: [
      {
        value: "Serving Trays",
        link: `/collections/66964b6ba19786a88722b249`,
      },
      {
        value: "Serving & Barware",
        link: `/collections/66964b6ba19786a88722b23a`,
      },
      {
        value: "Cutlery",
        link: `/collections/66964b6aa19786a88722b202`,
      },
      {
        value: "Tissue Boxes",
        link: `/collections/66964b6ba19786a88722b247`,
      },
      {
        value: "Coasters",
        link: `/collections/66964b6aa19786a88722b1ff`,
      },
      {
        value: "Table Linen",
        link: `/collections/66964b6ba19786a88722b245`,
      },
    ],
  },
  {
    value: "Planters & Garden",
    link: `/collections/66964b6aa19786a88722b1dc`,
    children: [
      {
        value: "Indoor Planters",
        link: `/collections/66964b6aa19786a88722b226`,
      },
      {
        value: "Artificial Flowers & Plants",
        link: `/collections/66964b6aa19786a88722b1ea`,
      },
    ],
  },
  {
    value: "Wall PLATEs",
    link: `/collections/66964b6aa19786a88722b205`,
    children: [],
  },
  {
    value: "Light & Lamps",
    link: `/collections/66964b6aa19786a88722b1df`,
    children: [
      {
        value: "Lamps",
        link: `/collections/66964b6aa19786a88722b228`,
      },
      {
        value: "Lanterns and Candle Holders",
        link: `/collections/66964b6aa19786a88722b229`,
      },
      {
        value: "All Lighting Accessories",
        link: `/collections/66964b6aa19786a88722b1df`,
      },
    ],
  },
  {
    value: "Gifts",
    link: `/collections/66964b6aa19786a88722b1dd`,
    children: [
      {
        value: "Gifts By Occasion",
        link: `/collections/66964b6aa19786a88722b216`,
        children: [
          {
            value: "Holi Essentials",
            link: `/collections/66964b6aa19786a88722b221`,
          },
          {
            value: "Christmas Gifts",
            link: `/collections/66964b6aa19786a88722b1fd`,
          },
          {
            value: "Father's Day",
            link: `/collections/66964b6aa19786a88722b20d`,
          },
          {
            value: "Diwali Gifts",
            link: `/collections/66964b6aa19786a88722b208`,
          },
          {
            value: "Easy To Carry Travel Gifts",
            link: `/collections/66964b6aa19786a88722b20a`,
          },
          {
            value: "Gifts for House Warming and Griha Parvesh",
            link: `/collections/66964b6aa19786a88722b21e`,
          },
        ],
      },
      {
        value: "Gifts By Price",
        link: `/collections/66964b6aa19786a88722b217`,
        children: [
          {
            value: "Below Rs 1000",
            link: `/collections/66964b6aa19786a88722b210`,
          },
          {
            value: "Below Rs 2000",
            link: `/collections/66964b6aa19786a88722b212`,
          },
          {
            value: "Below Rs 3000",
            link: `/collections/66964b6aa19786a88722b213`,
          },
          {
            value: "Below Rs 4000",
            link: `/collections/66964b6aa19786a88722b214`,
          },
          {
            value: "Below Rs 5000",
            link: `/collections/66964b6aa19786a88722b215`,
          },
          {
            value: "Below Rs 10000",
            link: `/collections/66964b6aa19786a88722b211`,
          },
        ],
      },
      {
        value: "Gifts By Recipient",
        link: `/collections/66964b6aa19786a88722b218`,
        children: [
          {
            value: "Gifts for Host & Hostess",
            link: `/collections/66964b6aa19786a88722b222`,
          },
          {
            value: "Gifts for Entertainers",
            link: `/collections/66964b6aa19786a88722b21a`,
          },
          {
            value: "Gifts for Garden Lovers",
            link: `/collections/66964b6aa19786a88722b21b`,
          },
          {
            value: "Gifts for Decorators",
            link: `/collections/66964b6aa19786a88722b219`,
          },
        ],
      },
      {
        value: "Gifts for Men",
        link: `/collections/66964b6aa19786a88722b21d`,
        children: [
          {
            value: "Luxury Gifts for Men",
            link: `/collections/66964b6aa19786a88722b22b`,
          },
          {
            value: "Father's Day Gifts",
            link: `/collections/66964b6aa19786a88722b20d`,
          },
          {
            value: "Rakhi Gifts for Brothers",
            link: `/collections/66964b6ba19786a88722b237`,
          },
        ],
      },
      {
        value: "Gifts for Women",
        link: `/collections/66964b6aa19786a88722b21c`,
        children: [
          {
            value: "Expensive Gifts for Women",
            link: `/collections/66964b6aa19786a88722b20c`,
          },
          {
            value: "Mother's Day Gifts",
            link: `/collections/66964b6ba19786a88722b22f`,
          },
          {
            value: "Rakhi Gifts for Sisters",
            link: `/collections/66964b6ba19786a88722b238`,
          },
        ],
      },
      {
        value: "Gift Cards",
        link: `${config.brandURL}/products/mail-a-gift-card`,
        children: [{}],
        utm: true,
      },
    ],
  },
];
