import Link from "next/link";
import styled from "@emotion/styled";
import { Close, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  List,
  ListItem as MuiListItem,
  ListItemButton as MuiListItemButton,
  Typography,
} from "@mui/material";

import config from "config/env";
import { MenuItems } from "utils/drawerItem";
import { setURL } from "utils/outclicks";
import { SearchParamsHook } from "lib/utm";

const ListItem = styled(MuiListItem)`
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  padding: 0px 20px;
  justify-content: left;
`;

const ListItemButton = styled(MuiListItemButton)`
  font-family: Nunito Sans;
  font-size: 1.01em;
  padding: 15px 0px;
  justify-content: left;
`;

const AccordionTypography = styled(Typography)`
  font-family: Nunito Sans !important;
  font-size: 1.01em;
  padding: 15px 0px;
  justify-content: left;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  line-height: 2;
`;

const ChildTypography = styled(Typography)`
  font-family: Nunito Sans !important;
  font-size: 14px;
  padding: 7.5px 0px 7.5px 0px;
  justify-content: left;
  text-transform: capitalize;
  letter-spacing: 0.025em;
  line-height: 2;
  color: #000;
`;

const LowerLinks = styled(Typography)`
  font-family: Nunito Sans !important;
  font-size: 14px;
  padding: 15px 0px 5px;
  letter-spacing: 0.025em;
  line-height: 2;
  color: #000;
`;

const StyledAccordionSummary = styled(AccordionSummary)({
  position: "relative",
  "&::before": {
    height: "40px",
    position: "absolute",
    right: "42px",
    top: "12px",
    bottom: "0",
    width: "1px",
    backgroundColor: "#e8e8e1",
    content: '""',
  },
});

export default function MobileDrawer(props) {
  const { open, setOpen } = props;
  const queryParams = SearchParamsHook();
  return (
    <Drawer
      sx={{ zIndex: "999999" }}
      anchor="left"
      open={open}
      onClose={() => setOpen(false)}
      className="C004"
    >
      <Box sx={{ width: 350 }} role="presentation" className="C004">
        <Box
          display="flex"
          justifyContent="flex-end"
          padding="22px 5px"
          className="C004"
        >
          <Button
            onClick={() => setOpen(false)}
            sx={{
              color: "#000",
              opacity: "0.5",
              justifyContent: "flex-end",
              paddingTop: 0,
            }}
            className="C004-close"
          >
            <Close className="C004-close" />
          </Button>
        </Box>
        <List sx={{ p: "0px" }} className="C004-menu-items">
          {MenuItems.map((item, idx) => {
            if (item.children.length) {
              return (
                <Box
                  key={idx}
                  padding="0px 20px"
                  className={`C004-menu-items-${idx}`}
                >
                  <Divider sx={{ width: "100%", borderColor: "#e8e8e1" }} />
                  <Accordion
                    elevation={0}
                    sx={{
                      border: "0px solid #e8e8e1",
                      "&::before": { backgroundColor: "#e8e8e1" },
                      boxShadow: "none",
                      "& .MuiAccordionSummary-content": { m: "0px" },
                    }}
                    disableGutters
                    className={`C004-menu-items-${idx}`}
                  >
                    <StyledAccordionSummary
                      expandIcon={
                        <ExpandMore
                          sx={{
                            color: "#2e2e2e63",
                          }}
                          className={`C004-menu-items-${idx}-expand`}
                        />
                      }
                      sx={{ p: "0px" }}
                    >
                      <Link
                        href={item.link}
                        style={{
                          width: "80%",
                          textAlign: "left",
                          color: "#000",
                        }}
                        className={`C004-menu-items-${idx}`}
                      >
                        <AccordionTypography
                          onClick={() => setOpen(false)}
                          className={`C004-menu-items-${idx}`}
                        >
                          {item.value}
                        </AccordionTypography>
                      </Link>
                    </StyledAccordionSummary>
                    <AccordionDetails
                      sx={{ padding: "0px 0px 16px" }}
                      className={`C004-menu-items-${idx}-details`}
                    >
                      {item.children.map((child, Childidx) => {
                        if (child.children && child.children.length > 1) {
                          return (
                            <Accordion
                              key={`${idx}-${Childidx}`}
                              elevation={0}
                              sx={{
                                border: "0px solid #e8e8e1",
                                "&::before": { backgroundColor: "#fff" },
                                boxShadow: "none",
                              }}
                              disableGutters
                              className={`C004-menu-items-${idx}-details-${Childidx}`}
                            >
                              <AccordionSummary
                                expandIcon={
                                  <ExpandMore
                                    sx={{
                                      color: "#2e2e2e63",
                                      border: "1px solid #e8e8e1",
                                      borderRadius: "50%",
                                    }}
                                    className={`C004-menu-items-${idx}-details-${Childidx}-expand`}
                                  />
                                }
                                aria-controls="panel1-content"
                                sx={{ p: "0px", border: "0px solid #000" }}
                                className={`C004-menu-items-${idx}-details-${Childidx}`}
                              >
                                <Box
                                  width="fit-content"
                                  className={`C004-menu-items-${idx}-details-${Childidx}`}
                                >
                                  <Link
                                    href={child.link}
                                    style={{
                                      width: "100%",
                                      textAlign: "left",
                                      color: "#000",
                                    }}
                                    className={`C004-menu-items-${idx}-details-${Childidx}`}
                                  >
                                    <ChildTypography
                                      onClick={() => setOpen(false)}
                                      className={`C004-menu-items-${idx}-details-${Childidx}`}
                                    >
                                      {child.value}
                                    </ChildTypography>
                                  </Link>
                                </Box>
                              </AccordionSummary>
                              <AccordionDetails
                                sx={{ p: "0px" }}
                                className={`C004-menu-items-${idx}-details-${Childidx}-details`}
                              >
                                {child.children.length > 1 &&
                                  child.children.map(
                                    (subChild, subChildIdx) => {
                                      return (
                                        <Box
                                          sx={{
                                            borderLeft: "1px solid #000",
                                            pl: "15px",
                                          }}
                                          key={`${idx}-${Childidx}-${subChildIdx}`}
                                          className={`C004-menu-items-${idx}-details-${Childidx}-details-${subChildIdx}`}
                                        >
                                          <Link
                                            href={subChild.link || ""}
                                            style={{
                                              width: "100%",
                                              textAlign: "left",
                                              color: "#000",
                                            }}
                                            className={`C004-menu-items-${idx}-details-${Childidx}-details-${subChildIdx}`}
                                          >
                                            <ChildTypography
                                              onClick={() => setOpen(false)}
                                              className={`C004-menu-items-${idx}-details-${Childidx}-details-${subChildIdx}`}
                                            >
                                              {subChild.value}
                                            </ChildTypography>
                                          </Link>
                                        </Box>
                                      );
                                    },
                                  )}
                              </AccordionDetails>
                            </Accordion>
                          );
                        }
                        return (
                          <Box
                            key={`${idx}-${Childidx}`}
                            className={`C004-menu-items-${idx}-details-${Childidx}`}
                          >
                            <Link
                              href={
                                child.utm
                                  ? setURL(child.link, queryParams)
                                  : child.link
                              }
                              style={{
                                width: "100%",
                                textAlign: "left",
                                color: "#000",
                              }}
                              className={`C004-menu-items-${idx}-details-${Childidx}`}
                            >
                              <ChildTypography
                                onClick={() => setOpen(false)}
                                className={`C004-menu-items-${idx}-details-${Childidx}`}
                              >
                                {child.value}
                              </ChildTypography>
                            </Link>
                          </Box>
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                </Box>
              );
            }
            return (
              <ListItem key={idx} className={`C004-menu-items-${idx}`}>
                <Divider sx={{ width: "100%", borderColor: "#e8e8e1" }} />
                <Link
                  href={item.link}
                  style={{ width: "100%", textAlign: "left", color: "#000" }}
                  className={`C004-menu-items-${idx}`}
                >
                  <ListItemButton
                    onClick={() => setOpen(false)}
                    className={`C004-menu-items-${idx}`}
                  >
                    {item.value}
                  </ListItemButton>
                </Link>
              </ListItem>
            );
          })}
        </List>
      </Box>
      <Grid container padding="0px 20px" className="C004-menu-footer">
        <Divider sx={{ width: "100%", borderColor: "#e8e8e1" }} />
        <Grid item xs={6} mt={1}>
          <Link
            href={setURL(`${config.brandURL}/apps/return_prime`, queryParams)}
            className="C004-menu-footer-returns"
          >
            <LowerLinks className="C004-menu-footer-returns">
              Returns
            </LowerLinks>
          </Link>
          <Link
            href={setURL(
              `${config.brandURL}/pages/bulk-order-enquiry`,
              queryParams,
            )}
            className="C004-menu-footer-bulk"
          >
            <LowerLinks className="C004-menu-footer-bulk">
              Bulk Orders
            </LowerLinks>
          </Link>
        </Grid>
        <Grid item xs={6} mt={1}>
          <Link
            href={setURL(`${config.brandURL}/pages/gst-bill`, queryParams)}
            className="C004-menu-footer-gst"
          >
            <LowerLinks className="C004-menu-footer-gst">GST Bill</LowerLinks>
          </Link>
          <Link
            href={setURL(`${config.brandURL}/pages/contact-us`, queryParams)}
            className="C004-menu-footer-contact"
          >
            <LowerLinks className="C004-menu-footer-contact">
              Contact Us
            </LowerLinks>
          </Link>
        </Grid>
        <Grid item xs={6}>
          <Link
            href={setURL(`${config.brandURL}/pages/about-us`, queryParams)}
            className="C004-menu-footer-about"
          >
            <LowerLinks className="C004-menu-footer-about">About Us</LowerLinks>
          </Link>
        </Grid>
      </Grid>
    </Drawer>
  );
}
