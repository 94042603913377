import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";

export default function SwipperDefault(props) {
  const { slides = [], sliderId } = props;
  return (
    <Swiper
      autoplay={{
        delay: 2000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      }}
      modules={[Autoplay]}
      loop
      style={{ width: "100%", height: "100%" }}
      className="C001-announcement"
    >
      {slides.map((item, idx) => (
        <SwiperSlide
          className={`C001-announcement-${idx}`}
          key={`${sliderId}-${idx}`}
          style={{ cursor: "pointer" }}
        >
          {item}
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
